/*!
 * Alert Bar Component SCSS
 */
/*!
 * Fonts Imports
 *
 * Fonts used in the Cengage Learning site. Font face declarations are in
 * separate files to keep things cleaner
 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../../../fonts/open-sans/Light/OpenSans-Light.woff2") format("woff2"), url("../../../fonts/open-sans/Light/OpenSans-Light.woff") format("woff"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../../../fonts/open-sans/Regular/OpenSans-Regular.woff2") format("woff2"), url("../../../fonts/open-sans/Regular/OpenSans-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("../../../fonts/open-sans/SemiBold/OpenSans-SemiBold.woff2") format("woff2"), url("../../../fonts/open-sans/SemiBold/OpenSans-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../../../fonts/open-sans/Bold/OpenSans-Bold.woff2") format("woff2"), url("../../../fonts/open-sans/Bold/OpenSans-Bold.woff") format("woff"); }

@font-face {
  font-family: "summer";
  font-display: swap;
  src: local("summer"), url("../../../fonts/summer/Summer-Font-Regular.woff") format("woff"); }

@font-face {
  font-family: "summer-italic";
  font-display: swap;
  src: local("summer-italic"), url("../../../fonts/summer/Summer-Font-Regular-Italic.woff") format("woff"); }

@font-face {
  font-family: "summer-bold";
  font-display: swap;
  src: local("summer-bold"), url("../../../fonts/summer/Summer-Font-Bold.woff") format("woff"); }

@font-face {
  font-family: "summer-bold-italic";
  font-display: swap;
  src: local("summer-bold-italic"), url("../../../fonts/summer/Summer-Font-Bold-Italic.woff") format("woff"); }

@font-face {
  font-family: "summer-light";
  font-display: swap;
  src: local("summer-light"), url("../../../fonts/summer/Summer-Font-Light.woff") format("woff"); }

@font-face {
  font-family: "summer-light-italic";
  font-display: swap;
  src: local("summer-light-italic"), url("../../../fonts/summer/Summer-Font-Light-Italic.woff") format("woff"); }

@font-face {
  font-family: "font-awesome";
  font-display: block;
  src: local("font-awesome"), local("fontawesome"), local("FontAwesome"), url("../../../fonts/font-awesome/fontawesome-webfont.woff2") format("woff2"), url("../../../fonts/font-awesome/fontawesome-webfont.woff") format("woff"), url("../../../fonts/font-awesome/fontawesome-webfont.ttf") format("truetype"), url("../../../fonts/font-awesome/fontawesome-webfont.eot") format("embedded-opentype"), url("../../../fonts/font-awesome/fontawesome-webfont.svg") format("svg"); }

@font-face {
  font-family: 'Trade Gothic';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Trade Gothic Bold"), local("TradeGothic-Bold"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwenty.woff2") format("woff2"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwenty.woff") format("woff"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwenty.ttf") format("truetype"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwenty.eot") format("embedded-opentype"); }

@font-face {
  font-family: 'Trade Gothic';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Trade Gothic BoldItalic"), local("TradeGothic-BoldItalic"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwentyItalic.woff2") format("woff2"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwentyItalic.woff") format("woff"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwentyItalic.ttf") format("truetype"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwentyItalic.eot") format("embedded-opentype"); }

/*!
 * Variables SCSS
 *
 * General variables used throughout the SCSS for Cengage Learning
 */
/*!
 * Contrast SCSS
 *
 * Calculates a numeric representation of the contrast between two colors, and
 * provides a function for picking the best contrasting color from a list
 */
/*!
 * Math SCSS
 *
 * Various potentially useful SCSS functions for mathematical calculations
 */
/*!
 * BEM (Block, Element, Modifier) Mixins SCSS
 *
 * Custom mixins for handling BEM-style class naming. Have tried this using existing
 * third-party SCSS libraries, but the way we apply variations in Hippo-based site
 * structure, those make a mess of nesting of things. The goal of these mixins is to
 * remove some of the ugly nesting issues while still making it easy to apply BEM-style
 * class naming (which has some good benefits)
 */
/*!
 * Buttons Mixins SCSS
 *
 * Styling for special button (and button-like) controls
 */
/*!
 * Component Mixins SCSS
 *
 * Styling to normalize all Hippo components (and component-like elements)
 */
/*!
 * Device Frame Image Layers SCSS
 *
 * Mixins for applying those odd little device frame images to things
 */
/*!
 * Dropdown Menu Button
 *
 * Styling for a dropdown menu (button version)
 */
/*!
 * Dropdown Menu List
 *
 * Styling for a dropdown menu (list version)
 */
/*!
 * Flexbox Mixins SCSS
 *
 * Styling to support flexbox in browser that support it, and graceful fallbacks
 * for any non-flexbox browsers
 */
/*!
 * Graphics (Images and Videos) SCSS
 *
 * Mixins to set properties on images and videos
 */
/*!
 * Links SCSS
 *
 * Styling for links with hover and active behaviors
 */
/*!
 * Lists SCSS
 *
 * Helper mixins for styling lists consistently
 */
/*!
 * Modals SCSS
 *
 * Helper mixins for styling modals consistently
 */
/*!
 * Nav Bar Mixins SCSS
 *
 * Styling for a simple nav bar of links; used for Simple Nav and Product Section Simple Nav
 */
/*!
 * Panel Mixins
 *
 * Bootstrap's panel classes are oddly complicated to override, so
 * here's some new mixins based on them to make it easier
 */
/*!
 * Promo Layouts Mixins SCSS
 *
 * Styling for a single promo with content; used for Promo Component
 */
/*!
 * Responsive Square Mixins SCSS
 *
 * Apply to something you want to be responsively square
 */
/*!
 * Sprites SCSS
 *
 * Helper mixins for styling SVG sprites (either inline or as img tags)
 */
/*
* $color parameter can by a string or a map: 
* - $color: will add the color tho the svg > path by default;
* - $color: will support a Sass Map: (svgElementA:colorA, svgElementB:colorB)
* 															like: (polygon: white, cirle: white)
*/
/*!
 * Tabbed Layouts Mixins SCSS
 *
 * Styling for a tabbed layout
 */
/*!
 * Text Variants SCSS
 *
 * The following are mixins or placeholders for standardizing the display of
 * fonts across all parts and components of the site. No CSS related to font
 * declarations should be set anywhere else in the CSS; these mixins and
 * placeholders should always be used to keep consistency
 *
 * Try to place font declarations as close as possible to the text element to
 * minimize complex nested em calculations. Each component is reset to basetextsize to
 * help with this.
 */
/*!
 * Variables SCSS
 *
 * General variables used throughout the SCSS for Cengage Learning
 */
/*!
 * Links SCSS
 *
 * Styling for links with hover and active behaviors
 */
/*!
 * Text Variants SCSS
 *
 * The following are mixins or placeholders for standardizing the display of
 * fonts across all parts and components of the site. No CSS related to font
 * declarations should be set anywhere else in the CSS; these mixins and
 * placeholders should always be used to keep consistency
 *
 * Try to place font declarations as close as possible to the text element to
 * minimize complex nested em calculations. Each component is reset to basetextsize to
 * help with this.
 */
/*!
 * Video Layouts Mixins SCSS
 *
 * Styling for a single video with content; used for Video (Single) and Product Section Video (Single)
 */
/*!
 * Rich Text Area
 *
 * Placeholders to set up all the defaults for use in a rich text area
 */
.ceng-alertBar_content {
  font-family: "Open Sans", sans-serif;
  font-weight: 400; }
  .ceng-alertBar_content * {
    margin: 0;
    border: 0;
    padding: 0; }
  .ceng-alertBar_content *:not(p) {
    display: inline; }
  .ceng-alertBar_content strong {
    font-family: "Open Sans", sans-serif;
    font-weight: 600; }
  .ceng-alertBar_content u {
    text-decoration: underline; }
  .ceng-alertBar_content em {
    font-style: italic; }
  .ceng-alertBar_content s {
    text-decoration: line-through; }
  .ceng-alertBar_content sub {
    font-size: 0.8em; }
  .ceng-alertBar_content sup {
    font-size: 0.8em; }
  .ceng-alertBar_content *:first-child {
    margin-top: 0; }
  .ceng-alertBar_content *:last-child {
    margin-bottom: 0; }
  .ceng-alertBar_content p {
    display: block;
    margin: 0 0 0.5em 0;
    border: 0;
    padding: 0; }
  .ceng-alertBar_content .ceng-rte_textSmall {
    font-size: 0.875em;
    line-height: 1em; }
  .ceng-alertBar_content .ceng-rte_textSmaller {
    font-size: 0.8125em;
    line-height: 1em; }

.ceng-alertBar {
  width: 100%;
  position: relative;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1em;
  overflow: hidden;
  max-height: 0; }
  .ceng-alertBar:before, .ceng-alertBar:after {
    display: table;
    content: " "; }
  .ceng-alertBar:after {
    clear: both; }
  .ceng-alertBar:first-child {
    margin-top: 0; }
  .ceng-alertBar:last-child {
    margin-bottom: 0; }
  .ceng-alertBar.is-open {
    max-height: 500px;
    height: auto; }
  .ceng-alertBar--animated {
    -webkit-transition: height .25s ease-in-out, max-height .25s ease-in-out;
    transition: height .25s ease-in-out, max-height .25s ease-in-out; }
  .ceng-alertBar_panel {
    position: relative;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
  .ceng-alertBar--inset .ceng-alertBar_panel {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
  .ceng-alertBar_panel--linkWhite a, .ceng-alertBar_panel--linkWhite a:hover, .ceng-alertBar_panel--linkWhite a:visited, .ceng-alertBar_panel--linkWhite a:active {
    background: transparent;
    color: white; }
  .ceng-alertBar_panel--linkWhite .ceng-sprite > svg path {
    fill: white; }
  .ceng-alertBar_panel--linkWhite .ceng-sprite,
  .ceng-alertBar_panel--linkWhite .ceng-sprite > svg,
  .ceng-alertBar_panel--linkWhite .ceng-sprite > img {
    display: inline-block;
    width: 10px;
    height: 10px; }
  .ceng-alertBar_panel--linkLightBlue a, .ceng-alertBar_panel--linkLightBlue a:hover, .ceng-alertBar_panel--linkLightBlue a:visited, .ceng-alertBar_panel--linkLightBlue a:active {
    background: transparent;
    color: #006298;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 1em; }
  .ceng-alertBar_panel--linkLightBlue .ceng-sprite > svg path {
    fill: #006298; }
  .ceng-alertBar_panel--linkLightBlue .ceng-sprite,
  .ceng-alertBar_panel--linkLightBlue .ceng-sprite > svg,
  .ceng-alertBar_panel--linkLightBlue .ceng-sprite > img {
    display: inline-block;
    width: 10px;
    height: 10px; }
  .ceng-alertBar_panel--linkDarkBlue a, .ceng-alertBar_panel--linkDarkBlue a:hover, .ceng-alertBar_panel--linkDarkBlue a:visited, .ceng-alertBar_panel--linkDarkBlue a:active {
    background: transparent;
    color: #003865; }
  .ceng-alertBar_panel--linkDarkBlue .ceng-sprite > svg path {
    fill: #003865; }
  .ceng-alertBar_panel--linkDarkBlue .ceng-sprite,
  .ceng-alertBar_panel--linkDarkBlue .ceng-sprite > svg,
  .ceng-alertBar_panel--linkDarkBlue .ceng-sprite > img {
    display: inline-block;
    width: 10px;
    height: 10px; }
  .ceng-alertBar_panel--textLight {
    color: white; }
  .ceng-alertBar_panel--textDark {
    color: #003865; }
  .ceng-alertBar_panel--textRed {
    color: #e0004d; }
  .ceng-alertBar--inset .ceng-alertBar_content {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
  .ceng-alertBar_content {
    position: relative;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin: -5px 0;
    width: 100%; }
    .ceng-alertBar_content--textLeft {
      margin-left: 0;
      margin-right: auto;
      text-align: left; }
      .ceng-alertBar_content--textLeft .ceng-alertBar_contentText {
        margin-right: auto; }
    .ceng-alertBar_content--textCenter {
      text-align: center; }
      .ceng-alertBar_content--textCenter .ceng-alertBar_contentText {
        margin: 0 auto; }
    .ceng-alertBar_content--textRight {
      margin-right: 0;
      margin-left: auto;
      text-align: right;
      right: 0;
      left: auto !important; }
    .ceng-alertBar_content--vCenter {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%); }
  .ceng-alertBar_hippoContent {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding: 5px 0; }
  .ceng-alertBar_close {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.71429em;
    margin-left: 10px;
    padding: 5px 0;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0; }
  @media (min-width: 768px) {
  .ceng-alertBar--inset .ceng-alertBar_panel {
    padding-left: -webkit-calc((100% - 710px) /2);
    padding-left: calc((100% - 710px) /2);
    padding-right: -webkit-calc((100% - 710px) /2);
    padding-right: calc((100% - 710px) /2); }
  .ceng-alertBar--inset .ceng-alertBar_content {
    padding-left: -webkit-calc((100% - 710px) /2);
    padding-left: calc((100% - 710px) /2);
    padding-right: -webkit-calc((100% - 710px) /2);
    padding-right: calc((100% - 710px) /2); }
  .ceng-alertBar_content {
    padding: 10px 0;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: -5px 0; } }
  @media (min-width: 992px) {
  .ceng-alertBar--inset .ceng-alertBar_panel {
    padding-left: -webkit-calc((100% - 930px) /2);
    padding-left: calc((100% - 930px) /2);
    padding-right: -webkit-calc((100% - 930px) /2);
    padding-right: calc((100% - 930px) /2); }
  .ceng-alertBar--inset .ceng-alertBar_content {
    padding-left: -webkit-calc((100% - 930px) /2);
    padding-left: calc((100% - 930px) /2);
    padding-right: -webkit-calc((100% - 930px) /2);
    padding-right: calc((100% - 930px) /2); } }
  @media (min-width: 1200px) {
  .ceng-alertBar--inset .ceng-alertBar_panel {
    padding-left: -webkit-calc((100% - 1130px) /2);
    padding-left: calc((100% - 1130px) /2);
    padding-right: -webkit-calc((100% - 1130px) /2);
    padding-right: calc((100% - 1130px) /2); }
  .ceng-alertBar--inset .ceng-alertBar_content {
    padding-left: -webkit-calc((100% - 1130px) /2);
    padding-left: calc((100% - 1130px) /2);
    padding-right: -webkit-calc((100% - 1130px) /2);
    padding-right: calc((100% - 1130px) /2); } }
